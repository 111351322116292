import React from "react"
import DefaultLayout from "../layouts/DefaultLayout";
import ProfilePicture from "../components/ProfilePicture";
import {graphql} from "gatsby";

const crumbs = [
  {
    title: 'Αρχική',
    url: '/'
  },
  {
    title: 'Γνωρίστε Μας'
  },
  {
    title: 'Ποιοι Είμαστε'
  }
]

export default function OurTeam({data}) {
  let teamMembers = data.allTeamMember.edges
    .map(edge => edge.node);

  return <DefaultLayout title='Ποιοι Είμαστε' crumbs={crumbs}>
    <div className={'row content-section'}>
      <h3 style={{paddingBottom: '10px', paddingTop: '10px'}}>Γνωρίστε Μας</h3>
    </div>
    <div className={'row content-section'}>
      {teamMembers.map(member => (
        <ProfilePicture key={member.name} profile={member}/>
      ))}
    </div>
  </DefaultLayout>
}

export const query = graphql`
  query {
    allTeamMember {
      edges {
        node {
          name
          title
          location
          img
        }
      }
    }
  }
`