import React from "react";

function ProfilePicture(props) {
  const { profile } = props;

  return (
    <div className="col-md-4" style={{padding: '10px'}}>
      <div style={{position: 'relative', textAlign: 'center', color: 'white'}}>
        <img src={profile.img} style={{height: 'auto', width: '100%'}} alt={profile.name}/>
        <div style={{position: 'absolute', bottom: '0px', left: '0px', height: '45px', width: '100%', backgroundColor: 'black', opacity: '0.5'}}/>
        <div style={{position: 'absolute', bottom: '21px', left: '16px', fontWeight: 'bold'}}>{profile.name}</div>
        <div style={{position: 'absolute', bottom: '5px', left: '16px', fontSize: '10px', fontStyle: 'italic'}}>{profile.title}</div>
      </div>
    </div>
  );
}

export default ProfilePicture;